import Alert from '@attendance-web-app/components/Common/Alert';
import CustomModal from '@attendance-web-app/components/Common/CustomModal';
import ErrorLabel from '@attendance-web-app/components/Common/ErrorLabel';
import { NewLoadingScreen } from '@attendance-web-app/components/Common/LoadingScreen';
import { convert12FormatTo24Format } from '@attendance-web-app/libs/date';
import vendorServices from '@attendance-web-app/services/vendor.services';
import { IEditVendorForms, IEditVendorModal } from '@attendance-web-app/types/vendor/type';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { SubmitHandler, useForm } from 'react-hook-form';

const EditVendorModal = ({ show, onHide, vendorDetail, refetchVendor }: IEditVendorModal) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showTime, setShowTime] = useState(true);
  const {
    id,
    emailAddress,
    firstName,
    lastName,
    phoneNumber,
    location,
    vendorName: vendorRestaurantName,
    locationUrl,
    openingHour,
    closingHour,
    vatOrPanNumber
  } = vendorDetail ?? {};

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
    setError
  } = useForm<IEditVendorForms>({
    defaultValues: {
      emailAddress,
      firstName,
      lastName,
      phoneNumber,
      location,
      vendorRestaurantName,
      locationUrl,
      openingHour,
      closingHour,
      vatNumber: vatOrPanNumber
    }
  });

  useEffect(() => {
    setValue('emailAddress', emailAddress);
    setValue('firstName', firstName);
    setValue('lastName', lastName);
    setValue('phoneNumber', phoneNumber);
    setValue('location', location);
    setValue('vendorRestaurantName', vendorRestaurantName);
    setValue('locationUrl', locationUrl);
    setValue('openingHour', convert12FormatTo24Format(openingHour));
    setValue('closingHour', convert12FormatTo24Format(closingHour));
  }, [vendorDetail]);
  const handleEditVendor: SubmitHandler<IEditVendorForms> = async data => {
    try {
      setIsLoading(true);
      const updatedVendorBody = {
        emailAddress: data.emailAddress,
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: Number(data.phoneNumber),
        location: data.location,
        vendorName: data.vendorRestaurantName,
        closingHour: showTime ? data.closingHour : '23:59',
        locationUrl: data.locationUrl,
        openingHour: showTime ? data.openingHour : '00:00',
        vatOrPanNumber: data?.vatNumber
      };
      await vendorServices.editVendor(String(id), updatedVendorBody);
      const refetchParams = { id } as any;
      refetchVendor();
      onHide();
      Alert('success', 'Vendor has been updated successfully');
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      err?.response?.data?.message.map((message: any) => {
        if (message.includes('Vendor name')) {
          setError('vendorRestaurantName', { message: message });
        }
        if (message.includes('First name')) {
          setError('firstName', { message: message });
        }
        if (message.includes('Last name')) {
          setError('lastName', { message: message });
        }
        if (message.includes('Mobile number')) {
          setError('phoneNumber', { message: message });
        }
        if (message.includes('Location')) {
          setError('location', { message: message });
        }
        if (message.includes('emailAddress')) {
          setError('emailAddress', { message: message });
        }
        if (message.includes('Email address')) {
          setError('emailAddress', { message: message });
        }
        if (message.includes('locationUrl')) {
          setError('locationUrl', { message: message });
        }
        if (message.includes('openingHour')) {
          setError('openingHour', { message: message });
        }
        if (message.includes('closingHour')) {
          setError('closingHour', { message: message });
        }
        if (message.includes('vatOrPanNumber')) {
          setError('vatNumber', { message: message });
        }
      });
      if (err.message) {
        Alert('error', err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleOpen24Hours = (event: any) => {
    setShowTime(!event.target.checked);
  };
  return (
    <NewLoadingScreen isLoading={isLoading}>
      <CustomModal
        showModal={show}
        handleHideModal={onHide}
        modalTitle="Edit Vendor"
        footerComponent={
          <>
            <button
              onClick={() => {
                onHide();
              }}
              className="btn-tertiary"
            >
              Cancel
            </button>
            <button form="edit-vendor-form" className="btn" disabled={isDirty ? false : true}>
              Edit Vendor
            </button>
          </>
        }
      >
        <form className="container p-0" id="edit-vendor-form" onSubmit={handleSubmit(handleEditVendor)}>
          <div className="mt-2">
            <Form.Group className="col-12 pe-0 form-group-id mb-4">
              <Form.Control
                className="body-regular "
                type="text"
                placeholder="Cafe name"
                {...register('vendorRestaurantName', { required: true })}
                isInvalid={!!errors.vendorRestaurantName}
              />
              <label
                htmlFor="floatingInputCustom"
                className={` ms-2  customlabel-id customlabel-name ${errors.vendorRestaurantName ? 'text-danger' : ''}`}
              >
                Vendor <span className="asteriskVendor">*</span>
              </label>
              {errors.vendorRestaurantName && (
                <ErrorLabel message={errors.vendorRestaurantName.message || 'Vendor name is required'} />
              )}
            </Form.Group>
            <Form.Group className="col-12 pe-0 form-group-id mb-4">
              <Form.Control
                className="body-regular"
                type="id"
                placeholder="John"
                {...register('firstName', { required: true })}
                isInvalid={!!errors.firstName}
              />
              <label
                htmlFor="floatingInputCustom"
                className={` ms-2  customlabel-id customlabel-name ${errors.firstName ? 'text-danger' : ''}`}
              >
                First name<span className="asteriskVendor">*</span>
              </label>
              {errors.firstName && <ErrorLabel message={errors.firstName.message || 'First name is required'} />}
            </Form.Group>
            <Form.Group className="col-12 pe-0 form-group-id mb-4">
              <Form.Control
                className="body-regular"
                type="id"
                placeholder="Doe"
                {...register('lastName', { required: true })}
                isInvalid={!!errors.lastName}
              />
              <label
                htmlFor="floatingInputCustom"
                className={` ms-2 customlabel-id customlabel-name ${errors.lastName ? 'text-danger' : ''}`}
              >
                Last name<span className="asteriskVendor">*</span>
              </label>
              {errors.lastName && <ErrorLabel message={errors.lastName.message || 'Last name is required'} />}
            </Form.Group>
            <Form.Group className="col-12 pe-0 form-group-id mb-4">
              <Form.Control
                className="body-regular"
                type="id"
                placeholder="9800000000"
                {...register('phoneNumber', {
                  required: true,
                  maxLength: 10,
                  validate: value => value.toString().length == 10 || 'Contact Number should be of 10 digits',
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'Contact Number should be only digits'
                  }
                })}
                isInvalid={!!errors.phoneNumber}
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className={` ms-2 customlabel-id customlabel-name ${errors.phoneNumber ? 'text-danger' : ''}`}
              >
                Contact number<span className="asteriskVendor">*</span>
              </label>
              {errors.phoneNumber && (
                <ErrorLabel message={errors.phoneNumber.message || 'Contact number is required'} />
              )}
            </Form.Group>
            <Form.Group className="col-12 pe-0 form-group-id mb-4">
              <Form.Control
                className="body-regular"
                type="id"
                placeholder="9800000000"
                {...register('vatNumber', {
                  required: true,
                  maxLength: 9,
                  validate: value => {
                    const isValidInteger = /^[0-9]+$/.test(value) && value.toString().length == 9;
                    return (
                      isValidInteger ||
                      'The VAT/PAN Number must be a positive integer with a minimum length of 9 digits.'
                    );
                  },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: 'VAT/PAN Number should be only digits'
                  }
                })}
                maxLength={9}
                isInvalid={!!errors.vatNumber}
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className={` ms-2 customlabel-id customlabel-name ${errors.vatNumber ? 'text-danger' : ''}`}
              >
                VAT/PAN Number<span className="asteriskVendor">*</span>
              </label>
              {errors.vatNumber && <ErrorLabel message={errors.vatNumber?.message || 'VAT/PAN Number is required'} />}
            </Form.Group>
            <Form.Group className="col-12 pe-0 form-group-id mb-4">
              <Form.Control
                className="body-regular"
                type="id"
                placeholder="Place"
                {...register('location', { required: true })}
                isInvalid={!!errors.location}
              />
              <label
                htmlFor="floatingInputCustom"
                className={` ms-2  customlabel-id customlabel-name ${errors.location ? 'text-danger' : ''}`}
              >
                Location<span className="asteriskVendor">*</span>
              </label>
              {errors.location && <ErrorLabel message={errors.location.message || 'Location is required'} />}
            </Form.Group>
            <Form.Group className="col-12 form-group-id mb-4">
              <Form.Control
                type="text"
                placeholder="Enter valid google map url."
                {...register('locationUrl')}
                isInvalid={!!errors.location}
                autoComplete="off"
              />
              <label
                htmlFor="floatingInputCustom"
                className={` customlabel-id customlabel-name  ${errors.location ? 'text-danger' : ''}`}
              >
                Google Map Url
              </label>
              {errors.location && <ErrorLabel message={errors.location.message || 'Location is required'} />}
            </Form.Group>
            <div className="selectTimeContainer">
              <label className="selectTimeLabel body-small-semibold">Select Time</label>
              <div className="selectTimeContainer__checkbox">
                <input type="checkbox" className="checkbox" onChange={handleOpen24Hours} />{' '}
                <label className="body-small-regular">Open 24 hours</label>
              </div>
              {showTime && (
                <div className="time__container">
                  <Form.Group className="col form-group-id">
                    <Form.Control
                      type="time"
                      placeholder="Open Time"
                      {...register('openingHour', { required: showTime })}
                      isInvalid={!!errors.openingHour}
                      autoComplete="off"
                      className="body-regular"
                    />
                    <label
                      htmlFor="floatingInputCustom"
                      className={` customlabel-id customlabel-name  ${errors.openingHour ? 'text-danger' : ''}`}
                    >
                      Open Time<span className="asteriskVendor">*</span>
                    </label>
                    {errors.openingHour && (
                      <ErrorLabel message={errors.openingHour.message || 'Opening Hour is required'} />
                    )}
                  </Form.Group>
                  <Form.Group className="col form-group-id">
                    <Form.Control
                      type="time"
                      {...register('closingHour', { required: showTime })}
                      isInvalid={!!errors.closingHour}
                      autoComplete="off"
                      className="body-regular"
                    />
                    <label
                      htmlFor="floatingInputCustom"
                      className={` customlabel-id customlabel-name  ${errors.closingHour ? 'text-danger' : ''}`}
                    >
                      Close Time<span className="asteriskVendor">*</span>
                    </label>
                    {errors.closingHour && (
                      <ErrorLabel message={errors.closingHour.message || 'Opening Hour is required'} />
                    )}
                  </Form.Group>
                </div>
              )}
            </div>
            <Form.Group className="col-12 pe-0 form-group-id mb-4">
              <Form.Control
                className="body-regular"
                type="id"
                placeholder="name@example.com"
                {...register('emailAddress', { required: true })}
                isInvalid={!!errors.emailAddress}
              />
              <label
                htmlFor="floatingInputCustom"
                className={` ms-2 customlabel-id customlabel-name ${errors.emailAddress ? 'text-danger' : ''}`}
              >
                Email address<span className="asteriskVendor">*</span>
              </label>
              {errors.emailAddress && (
                <ErrorLabel message={errors.emailAddress.message || 'Email Address is required'} />
              )}
            </Form.Group>
          </div>
        </form>
      </CustomModal>
    </NewLoadingScreen>
  );
};
export default EditVendorModal;
